import { mixinFormato } from "@/mixins/mixinFormato";
import { mixinComponente } from "@/mixins/mixinComponente";

export var mixinCt = {
  mixins: [mixinFormato, mixinComponente],

  data() {
    return {
      app: this.$store.state.G.APP,
      rol: this.$store.state.G.ROL,
      rolId: this.$store.state.G.ROLID,
      usu: this.$store.state.G.USUARIO,
      zona: this.$store.state.G.ZONA
    };
  },

  methods: {
    ctProp(prop, ct) {
      //  componente
      if (prop === "comp") {
        if (!ct[4]) return false;
        if (!ct[4].comp) return false;
        return true;
      }
      //
      if (!ct[3]) return false;

      // formato
      if (prop == "$") {
        let f = ct[3].find(a => a.includes(prop));
        return f == undefined ? false : f.slice(1);
      }
      // propiedades varias
      if (ct[3].indexOf(prop) > -1) return true;
      return false;
    },

    // paso valores del record a los controles
    async record2ctrl(record, ctrls, ini = false) {
      console.log("--- record2ctrl record", record);
      console.log("--- record2ctrl ctrls", ctrls);
      // recorro el schema y le asigno el valor del record al control
      //
      var ctrl;
      for (var key in ctrls) {
        ctrl = ctrls[key];

        // componente
        if (this.ctProp("comp", ctrl)) {
          // el componente es el responsable de meter en  el schema lo que sea necesario
          this[ctrl[4].comp.type]().set({ ctrl, ctrls, record, ini });
          continue;
        }
  
      //------------------------------------------------------------------
      // casos de asignacion de valores de record a control ctrl[2]
      // 1- el control No tiene definido tablefield ctrl[0]=''
      //    si tiene    defaultValue ctrl[2] se deja como valor
      //    si no tiene defaultValue ctrl[2]='' se asigna cadena vacia

      // 2 - el record[ctrl[0]] no existe
      //       se aplica el caso 1
      //       (avisar por console.log por posible error, es raro este caso)
      ctrl[2] = ctrl[0] && record[ctrl[0]] ? record[ctrl[0]] : ctrl[2] || "";

     // Paso dato formateado si tiene formato
      let formato = this.ctProp("$", ctrl);
      ctrl[2] = formato ? this.sql2visual(formato, ctrl[2]):ctrl[2];
      }
    
      //-------------------------------------------------------------------
      //
      //  FALTA APLICAR FORMATOS

      // ctrl[2]= this.get_fieldValue({ctrl, schema, record, ini});
      // if (ctrl.format) ctrl.value= this.sql2visual(ctrl, ctrl.value);

      console.log("--- record2ctrl (Mixin) --- schema", ctrls);
    },

    // paso valores de los controles a un record y lo devuelvo
    // soloDirencias=true  devuelve solo los cambios
    // soloDirencias=false devuelve el record completo con cambios y sin cambios
    ctrl2record(ctrls, record, soloDiferencias = true, nosave= true) {
      console.log("--- ctrl2record (Mixin) ---", ctrls);
      //
      var record_aux = {};
      var ctrl;
      Object.keys(ctrls).forEach(key => {
        ctrl = ctrls[key];
       
        // si tiene la propiedad nosave no paso
        if (nosave && this.ctProp("nosave", ctrl)) return;
      
        // si no tiene tableField no paso
        if (!ctrl[0]) return;
      
        // guardo el/los dato/s del componente en el record_aux
        if (this.ctProp("comp", ctrl)) {
          this[ctrl[4].comp.type]().get({ ctrl, ctrls, record, record_aux });
          return;
        }
        
        // Paso dato formateado si tiene formato
        let formato = this.ctProp("$", ctrl);
       
        record_aux[ctrl[0]] = formato
          ? this.visual2sql(formato, ctrl[2])
          : ctrl[2];
      });

      console.log(
        "record_aux antes de data_update: ",
        JSON.parse(JSON.stringify(record_aux))
      );
      // comparo record_aux con record y devuelvo los campos que han cambiado
      if (soloDiferencias) {
        var data_update = {};
        Object.keys(record_aux).forEach(key => {
          if (record_aux[key] == "" && record[key] == undefined) return;
          if (record_aux[key] == undefined && record[key] == "") return;
          if (record_aux[key] != record[key]) {           
            data_update[key] = record_aux[key];
          }
        });
        console.log(
          "record_aux despues de data_update: ",
          JSON.parse(JSON.stringify(data_update))
        );
        // devuelvo solo diferencias
        return data_update;
      }
      // devuelvo record completo
      return record_aux;
    }
    // de ctrls a record de XM
    // ctrl2linea(ctrls, record = {}) {
    //   console.log("--- ctrl2linea (Mixin) ---");

    //   Object.keys(ctrls).forEach(key => {
    //     if (ctrls[key].comp) record[key] = ctrls[key].comp.valueaux;

    //     if (!ctrls[key].f) return;
    //     record[ctrls[key].f] = ctrls[key].value;
    //   });
    //   return record;
    // },
  }
};
