import { mixinCt } from "@/mixins/mixinCt";
import { mixinValidaciones } from "@/mixins/mixinValidaciones";
import { mixinBtras } from "@/mixins/mixinBtras";
import storeM from "@/stores/modulos/storeM.js";

export var mixinM = {
  mixins: [mixinCt, mixinValidaciones, mixinBtras],

  data() {
    return {
      raiz:'',
      mtoItem:{},
      ct:0,
      apiArgs: {},
      storeName:'',
      showMto:false,

    }
  },

  props: {
    storeRaiz: { type: String, default: "" },
    modo: { type:String, default:'FM' },  // F, M, FM
    mtoAccion: { type: Object, default: () => {}},
    disparo: { type:Boolean, default: false },


    storeAux: { type: String, default: "" },
    recordAux: { type: Object, default: null }
  },


  async created() {

    // inicializo datos auxiliares generales (Mixin)
    this.iniDataMixin();

    // inicializo datos auxiliares particulares (comp)
    this.iniDataParticular();

    // monto nombre del store del componente que vamos a cargar.
    // formato:
    //   store recibido como propiedad (storeRaiz) +
    //   nombre store particular definido en el stIni (stIni.name) +
    //   modo carga del componente (F: sólo Finder, M: sólo Mto, FM: Finder con Mto) +
    //   número de 2 dígitos para diferenciar el store de un componente que se ha cargado varias veces
    let tmpName= this.storeRaiz + this.stIni.name + this.modo;
    let modules = Object.keys(this.$store._modules.root._children);
    let n=0, tmpN=0;

    if (!this.storeRaiz) {
      modules.forEach(elem=> {
        if (elem.substr(0, tmpName.length)!= tmpName) return;
        tmpN= Number(elem.slice(-2));
        if (tmpN> n) n= tmpN;
      });

      this.stIni.name= tmpName + String((n + 1)).padStart(2, "0");
      this.storeName= this.stIni.name;
      this.raiz= this.storeName;

    } else {
      this.stIni.name= tmpName;
      this.storeName= tmpName;
      this.raiz= this.storeRaiz;
    }


    // si no existe el store, lo registro
    if (!this.$store.state[this.stIni.name]) {
      this.$store.registerModule(this.storeName, storeM);
      let resultadocrear = await this.$store.dispatch(
        this.storeName + "/ini", { stIni:this.stIni, schAPI:this.apiArgs.sch }
      );

      // Pendiente: controlar error API
      console.log("created por primera vez", this.storeName, resultadocrear);
    }


    // watch disparo
    this.$watch("disparo", {
      immediate: true,
      handler() {
        console.log("disparo");
        this.watchAccion();
      }
    });
  },


  // elimino store antes de destruir el componente
  beforeDestroy() {
    this.$store.unregisterModule(this.storeName);
  },


  methods: {

    // ini Data General
    iniDataMixin() {
      console.log("DEV " + this.stIni.api + " ********************** (iniDataMixin)");

      //
      this.apiArgs= {
        sch: ["M", 'get_schema', {}, this.stIni.name ],
        get: ["M", 0, { id: 0 }, this.stIni.name ],
        set: ["M", 4, { id: 0, estado:'', changes: {}}, this.stIni.name ],
        del: ["M", 3, { id: 0 }, this.stIni.name ]
      }
    },


    // ini Data Particular
    iniDataParticular() {},


    // eventos de la cabecera
    eventHeader(evt) {
      console.log("DEV eventHeader ********** MIXINM", evt);

      if (this.storeRaiz== "") {
        this.$router.push({ path: '/' });
        this.$store.commit("set_activeMenu", []);
        return;
      }

      this.$emit('onEvent', evt);
    },


  // ---- Acciones Store ---------------------------------------------------------
    async stVer() {
      // apiArg: llamada API definida en el particular.
      // args: argumentos a fusionar con los definidos en 'fn_args' de apiArg.get
      let param= { apiArg:this.apiArgs.get, args:{ id: this.ID }};
      console.log("stVer *************************:", param);
      return await this.$store.dispatch(this.storeName + "/ver", param);
    },

    async stEditar() {
      // apiArg: llamada API definida en el particular.
      // args: argumentos a fusionar con los definidos en 'fn_args' de apiArgs.get
      let param= { apiArg:this.apiArgs.get, args:{ id: this.ID }};
      console.log("stEditar *************************:", param);
      return await this.$store.dispatch(this.storeName + "/editar", param);
    },

    async stCancelar() {
      return await this.$store.dispatch(this.storeName + "/cancelar");
    },

    async stNuevo() {
      return await this.$store.dispatch(this.storeName + "/nuevo");
    },

    async stEliminar() {
      // apiArg: llamada API definida en el particular.
      // args: argumentos a fusionar con los definidos en 'fn_args' de apiArgs.set
      let param= { apiArg:this.apiArgs.del, args:{ id: this.ID }};
      console.log("stEliminar *************************:", param);
      return await this.$store.dispatch(this.storeName + "/eliminar", param);
    },

    async stGuardar(record) {
      // apiArg: llamada API definida en el particular.
      // args: argumentos a fusionar con los definidos en 'fn_args' de apiArgs.set
      let param= { apiArg:this.apiArgs.set, args:{ id: this.ID, estado:this.estado, changes:record }};
      console.log("stGuardar *************************:", param);
      return await this.$store.dispatch(this.storeName + "/guardar", param);
    },
  // ----------------------------------------------------------------------


  // ---- Acciones Watch ---------------------------------------------------------
    watchAccion() {
      // actualizo ID, guardo mtoItem (acción, item) y ejecuto acción recibida
      if (!this.mtoAccion) return;
      this.mtoItem= this.mtoAccion;
      this.$store.commit(this.storeName + '/data2State', { prop:'ID', value:this.mtoItem.item.id });

      this.execAccion({ accion: this.mtoItem.accion });
    },
  //-----------------------------------------------------------------------


  // call Acciones M ---------------------------------------------------------
    execAccion(evt) {
      console.log("DEV execAccion ********** MIXINM", evt);

      // (0:'ver', 1:'nuevo', 2:'editar', 3:'eliminar', 4:'guardar', 5:'cancelar', 6:'salir')
      const options = [
        "ver",
        "nuevo",
        "editar",
        "eliminar",
        "guardar",
        "cancelar",
        "salir"
      ];
      let accion = /^([0-9])*$/.test(evt.accion)
        ? options[evt.accion]
        : evt.accion;

      if (this.$isFunction(this[accion])) this[accion](evt);
    },
  // ---------------------------------------------------------------------



  // acciones M ---------------------------------------------------------

    // muestro registro en modo NO edición
    async ver() {
      console.log('VER:: ', this.id);
      // call Store
      var apiResult = await this.stVer();
      console.log("apiResult ver M: ", apiResult);
      // Pendiente: controlar error API

      // guardo controles en ct y le paso los datos del registro leido
      this.ct = JSON.parse(JSON.stringify(this.sch));
      await this.record2ctrl(this.record, this.ct, false);

      // gancho
      this.verAfter();
    },

    // gancho DESPUÉS de obtener datos MD y pasarlos a controles
    verAfter() { return true },


    // muestro registro en modo edición
    async editar() {
      // call Store
      var apiResult = await this.stEditar();
      console.log("apiResult editar M: ", apiResult);
      // Pendiente: controlar error API

      // guardo controles en ct y paso los datos del registro leido
      this.ct = JSON.parse(JSON.stringify(this.sch));
      await this.record2ctrl(this.record, this.ct, false);
    },


    // cancelo la edición del registro
    async cancelar() {
      // si hay cambios, muestro pregunta para cancelarlos
      // si NO hay cambios, cancelo el registro directamente
      if (this.changesM()!='') {
        this.cancelarPregunta();

      } else {
        this.cancelarSi();
      }
    },

    async cancelarPregunta() {
      // muestro pregunta para confirmar la cancelación
      await await this.$root.$alert
        .open("¿ CANCELAR los cambios realizados ?", "confirm", null, [
          "NO",
          "SI"
        ])
        .then(r => {
          if (r) this.cancelarSi();
        });
    },

    cancelarSi() {
      // si estado es NUEVO, actualizo datos Store y cierro M
      if (this.estado == "nuevo") {
        this.stCancelar();
        this.$emit('onEvent', { accion: 6 });
        return;
      }

      // estado EDITAR. Actualizo datos Store y hago un VER
      this.stCancelar();
      this.execAccion({ accion: 0 });
      return;
    },


    // nuevo registro
    async nuevo() {
      // compruebo si hay mtos auxialiares en edición
      if (!this.auxEstado()) return;

      // call Store
      await this.stNuevo();

      // guardo controles en ct y los inicializo
      this.ct = JSON.parse(JSON.stringify(this.sch));
      await this.record2ctrl(this.record, this.ct, true);
    },


    // guardar registro
    async guardar() {
      // compruebo si hay mtos auxialiares en edición
      if (!this.auxEstado()) return false;

      // obtengo cambios (sólo cambios) y almaceno en recordChanges
      // si NO hay cambios y estamos en edición, hacemos un VER
      let recordChanges = this.ctrl2record(this.ct, this.record, true);
      console.log("recordChanges:", recordChanges);
      if (!Object.keys(recordChanges).length && this.estado == "editar") {
        this.execAccion({ accion: 0 });
        return;
      }


      // ------- CAMPO RELACIONADO
      // si estamos en un NUEVO, hemos definido un campo relacionado (stIni - particular)
      // y tenemos definido un relationID (data/computed - particular), guardamos ese ID en el record
      if (this.estado == "nuevo" && this.relation && (this.relationID && this.relationID > 0)) {
        recordChanges[this.relation] = this.relationID;
      }


      // call Store
      let apiResult= await this.stGuardar(recordChanges);
      console.log('apiResult guardar M: ', apiResult);
      // Pendiente: controlar error API

      // actualizo ID y después hacemos un VER
      this.$store.commit(this.storeName + '/data2State', { prop:'ID', value:apiResult.r[0][0].id });
      this.ver();
    },


    // elimino registro cabecera y todas sus lineas
    async eliminar() {
      // muestro pregunta para confirmar la eliminación
      await await this.$root.$alert
        .open("¿ Desea eliminar el registro ?", "confirm", null, [
          "NO",
          "SI"
        ])
        .then(r => {
          if (r) this.eliminarSi();
        });
    },

    async eliminarSi() {
      // call Store
      let apiResult= await this.stEliminar();
      console.log('apiResult eliminar M: ', apiResult);
      // Pendiente: controlar error API

      // ejecuto accion SALIR
      this.execAccion({ accion: 6 });
    },


    // cierro ventana MD
    salir(evt) {
      // si NO hay cambios o el estado es VER, cierro la ventana
      let msg= this.changesM();
      if (this.noEdit || msg== '') {
        this.$emit("onEvent", evt);
        return;
      }

      // muestro error obtenido al comprobar si hay cambios
      this.$root.$alert.open(msg, 'error', 2000);
    },
  // ---------------------------------------------------------------------



    // compruebo si hay una fila en edición (EDITAR/NUEVO), si está en edición muestro un error
    // y no continuo con la acción inicial, si no lo está, continuo
    auxEstado() {
      return true;
    },


    // compruebo si hay cambios en la cabecera y lineas
    // devuelvo '' si no hay cambios o el mensaje de error si si los hay
    changesM() {
      // si estado es NUEVO, devuelvo ''
      if (this.estado== 'nuevo') return '';

      let recordChanges = this.ctrl2record(this.ct, this.record, true);
      if (!Object.keys(recordChanges).length && !this.change1) return '';

      let msg= '';
      if (Object.keys(recordChanges).length || this.change1) msg+= 'Antes de Salir GUARDE o CANCELE los cambios';
      return msg;
    }


  },




  computed: {
  // variables STORE ---------------------------------------------------------
    ID(){
      return this.$store.state[this.storeName].ID;
    },

    noEdit() {
      return this.$store.getters[this.storeName + "/noEdit"];
    },

    estado() {
      return this.$store.state[this.storeName].estado;
    },

    perm() {
      return this.$store.state[this.storeName].perm;
    },

    sch() {
      if (this.apiArgs.sch== null) return this.schCtrls;
      return this.$store.state[this.storeName].sch;
    },

    btra() {
      return this.$store.state[this.storeName].btra;
    },
    record() {
      return this.$store.state[this.storeName].record;
    },

    change() {
      return this.$store.state[this.storeName].change;
    },

    relation() {
      return this.$store.state[this.storeName].relation;
    },

    permExtra() {
      return this.$store.state[this.storeName].permExtra;
    },

  // ---------------------------------------------------------------------

  }

};
