
export var mixinBtras = {

  methods: {

    // configuro el estado del btn recibo
    // view:true/false, disabled:true/false
    btnSet(btra, accion, { view= null, disabled=null }) { 
      let btn= this.findBtn(btra.btnsAccion, accion);
      if (!btn) return;
            
      if (view!= null) this.$set(btn, 'view', view);
      if (disabled!= null) this.$set(btn, 'disabled', disabled);     
    },


    // modifico los parámetros del btn recibo
    // según el objeto 'mods'
    btnReset(btra, accion, mods) {
      let btn= this.findBtn(btra.btnsAccion, accion);
      if (!btn) return;
      
      for (var key in mods) { 
        this.$set(btn, key, mods[key]);
      }    
    },   
    
    
    // busco la acción recibida en el array de botones
    findBtn(botonera, accion) {      
      let r= botonera.filter(elem=> elem.accion== accion)
      return r.length? r[0] : null; 
    }

  }
};
