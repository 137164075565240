<!--  -->

<template>
    <div class="ctw_descuadres">   
        <div class="conflex" style="justify-content:center">
        <v-sheet :elevation="4" style="width:500px">

            <!-- Cabecera -->
            <div class="cabecera">     
            <baseHeader           
                :cfg="$cfe.headers.mto"
                titulo="ContaWin - Descuadres"
                @onEvent="eventHeader"> 
            </baseHeader>
            </div>  

          <!-- Contenido -->
          <div class="contenedor"> 
            <div class="columna">
             
              <div style="display:flex">
                <v-select  
                    style="flex: 0 0 25%"              
                    v-bind="$select"
                    v-model="ct.tip[2]"     
                    :label="ct.tip[1]"                    
                    :items="tipos"
                    item-value="d"
                    item-text="n">            
                </v-select>
                <v-select  
                    style="flex: 0 0 25%"              
                    v-bind="$select"
                    v-model="ct.eje[2]"     
                    :label="ct.eje[1]"                                        
                    :items="ejercicios"
                    item-value="d"
                    item-text="n">            
                </v-select>
                <v-select  
                    style="flex: 0 0 35%"              
                    v-bind="$select"
                    v-model="ct.mes[2]"     
                    :label="ct.mes[1]"                                        
                    :items="meses"
                    item-value="d"
                    item-text="n">            
                </v-select>
                 <v-btn v-bind="$cfg.btra_cfg.standard" class="ml-2" title="Buscar" @click="leemos()">
                    <v-icon>{{'mdi-filter'}}</v-icon>
                </v-btn>

                <!-- Botones Mto -->
                <baseBtraExtra class="conflex" style="padding:10px;justify-content:center"
                  :modulo="btExtCfg"
                  @onEvent="execAccion">
                </baseBtraExtra>

            </div>
                               
              <hr class="mt-1">
              
              <!-- grid pagos -->                          
              <base_Fgrid
                :padre="stName" 
                :Entorno="Entorno"  
                height="350px"        
                :items-per-page=-1 
                hide-default-footer 
                fixed-header     
                dense>
          
              </base_Fgrid>

            </div>
          </div>
        </v-sheet> 
      </div>
  </div>
</template>


<script>

  import { mixinM } from "@/mixins/mixinM.js";
  import plugs from "@/common/general_plugs";
  const baseHeader = () => plugs.groute("baseHeader.vue", "base");
  const baseBtraExtra = () => plugs.groute("baseBtraExtra.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");

  export default {
    mixins: [mixinM],
    components: { 
      baseHeader, 
      baseBtraExtra,
      base_Fgrid,
    },
    
    props: {    

      padre: { type:String, default: '' },   
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }},            
    },


    data() {
      return {
        stIni: {
          api: "ctw_descuadres",
          name: "ctw_descuadres" // raiz + ciacM + (index si NO tiene raiz)
        },

        schCtrls: {               
            tip:["tip", "Tipo", ""], 
            eje:["eje", "Ejercicio", ""], 
            mes:["mes", "Mes", ""], 
                     
        },

        api:'',
        stName:'stctw_descuadres',           
        headers: {},   
        //
        tipos: [
            { d: '1', n: 'Servicios' },
            { d: '2', n: 'Asientos' },
        ],
        ejercicios: [
            {d: '2021', n: '2021' },
            {d: '2020', n: '2020' },
            {d: '2019', n: '2019' },
            {d: '2018', n: '2018' },
            {d: '2017', n: '2017' },
        ],
        meses: [
            {d: '0', n: 'Mes' },
            {d: '1', n: 'Enero' },
            {d: '2', n: 'Febrero' },
            {d: '3', n: 'Marzo' },
            {d: '4', n: 'Abril' },
            {d: '5', n: 'Mayo' },
            {d: '6', n: 'Junio' },
            {d: '7', n: 'Julio' },
            {d: '8', n: 'Agosto' },
            {d: '9', n: 'Septiembre' },
            {d: '10', n: 'Octubre' },
            {d: '11', n: 'Noviembre' },
            {d: '12', n: 'Diciembre' },
        ],

      }    
    },

    // mounted() {
    //     this.ini_data();

    // },
 

    methods: {
      async ini_component() { 
        /* // call API
        let args = { tipo: 'fnc', accion: 'facturas', fn_args: { accion: "pagos", fnt: 'get', fra: this.accionRow.id }};         
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        console.log('*get_records',apiResult);

        // actualizo registros del Grid
        this.$store.commit(this.stName + '/update_records', apiResult.r[0]); */
      }, 

      async iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");
      
        // pongo a null el sch para que no cargue del servidor el schema         
        this.apiArgs.sch= null;

        // controles particulares. No carga del servidor
        this.ct= JSON.parse(JSON.stringify(this.schCtrls));

        // defino los botones extra a mostrar
       /*  this.btExtCfg.btnsAccion= this.btExtCfg.btnsAccion.concat([
          { accion: "guardar", icono: "mdi-content-save", show: 1 },
        ]); */
                
                
        //grid
        this.headers = {
          header: [            
            { text: "Ref",   value: "doc", width: "20%" },            
            { text: "Fecha", value: "f", width: "20%"},
            { text: "Debe",  value: "d", width: "20%", filtro:'num' },
            { text: "Haber", value: "h", width: "20%", filtro:'num' },
            { text: "Saldo", value: "s", width: "20%", filtro:'num' },            
         ],
        };
        this.Entorno.grid.headers = this.set_headers();

        //
        this.ct.tip[2]='1';
        this.ct.eje[2]=new Date().getFullYear().toString;
        this.ct.tip[2]='0';

        this.$store.commit(this.stName + '/update_records', []);

      },

      async leemos() {
        let args = { tipo: 'fnc', accion: 'api', fn_args: { clase: "contawin", fnt: 'descuadre', 
          args: { tip: this.ct.tip[2], eje: this.ct.eje[2], mes: this.ct.mes[2] } } };
          //this.ctrl2linea(this.ct) } };            
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        console.log('*leemos',apiResult);

        // actualizo registros del Grid
        this.$store.commit(this.stName + '/update_records', apiResult.r[0]);

      },      
           


    }
  };
</script>
